import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';

import { getError, isResponseOK } from '../../../utils/functions';
import { RootState } from '../..';
import { InitialStateType } from './types';
import { clientTaskApi } from '../../../api/clientTaskApi/clientTaskApi';
import {
    CreateTaskCommentPropsType,
    CreateTaskDocPropsType,
    CreateTaskOfferDocProps,
    CreateTaskOfferPropsType,
    CreateTaskOfferRequestProps,
    CreateTaskPropsType,
    CreateTaskReminderPropsType,
    DelOrLoadTaskDocumentPropsType,
    DeleteTaskOfferDocProps,
    DeleteTaskReminderPropsType,
    GetAllClientTasksPropsType,
    GetOneClientTaskPropsType,
    UpdateTaskCommentPropsType,
    UpdateTaskOfferPropsType,
    UpdateTaskOfferRequestProps,
    UpdateTaskPropsType,
} from '../../../api/clientTaskApi/clientTaskApi.types';
import { ClientTaskInterface, PartnerInterface } from '../../../utils/types';
import { StatusResponseType } from '../../../api/clientDatabaseApi/clientDatabaseApi.types';
import { getCompany } from '../clientCardSlice';

export const createTask = createAsyncThunk<
    ClientTaskInterface,
    CreateTaskPropsType,
    {
        rejectValue: string;
    }
>('clientTask/createTask', async ({ companyId, ...props }, { rejectWithValue, dispatch }) => {
    try {
        const res = await clientTaskApi.createClientTask({ ...props, companyId });
        if (res?.message === 'Success' || res?.message === 'Task created') {
            dispatch(getCompany({ companyId }));
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getAllTasks = createAsyncThunk<
    {
        tasks: ClientTaskInterface[];
        count: number;
        guaranteeTasksCount: number;
        loanTasksCount: number;
        amount: number;
        clientComission: number;
        clientIncome: number;
    },
    GetAllClientTasksPropsType,
    {
        rejectValue: string;
    }
>('clientTask/getAllTasks', async ({ ...props }, { rejectWithValue, signal }) => {
    try {
        const res = await clientTaskApi.getClientTasks({ ...props, abortSignal: signal });
        if (res?.message === 'Success') {
            return {
                tasks: res.data.tasksList,
                count: res.data.total.records ?? 0,
                guaranteeTasksCount: res.data.total.guaranteeRecords ?? 0,
                loanTasksCount: res.data.total.loanRecords ?? 0,
                amount: res.data.total.bkw ?? 0,
                clientComission: res.data.total.clientKw ?? 0,
                clientIncome: res.data.total.clientIncome ?? 0,
            };
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const getOneClientTask = createAsyncThunk<
    ClientTaskInterface,
    GetOneClientTaskPropsType,
    {
        rejectValue: string;
    }
>('clientTask/getOneClientTask', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientTaskApi.getOneClientTask({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const updateTask = createAsyncThunk<
    StatusResponseType,
    UpdateTaskPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/updateTask',
    async ({ taskId, companyId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.updateClientTask({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getCompany({ companyId }));
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const getBanksListForTask = createAsyncThunk<
    PartnerInterface[],
    GetOneClientTaskPropsType,
    {
        rejectValue: string;
    }
>('clientTask/getBanksListForTask', async ({ ...props }, { rejectWithValue }) => {
    try {
        const res = await clientTaskApi.getBanksListForTask({ ...props });
        if (res?.message === 'Success') {
            return res.data;
        } else {
            throw res?.data;
        }
    } catch (e) {
        return rejectWithValue(getError(e));
    }
});

export const createTaskOffer = createAsyncThunk<
    StatusResponseType,
    CreateTaskOfferPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/createTaskOffer',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.createTaskOffer({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updateTaskOffer = createAsyncThunk<
    StatusResponseType,
    UpdateTaskOfferPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/updateTaskOffer',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.updateTaskOffer({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteTaskOfferDoc = createAsyncThunk<
    StatusResponseType,
    DeleteTaskOfferDocProps,
    {
        rejectValue: string;
    }
>(
    'clientTask/deleteTaskOffer',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.deleteTaskOfferDoc({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            }
        } catch (e) {
            return rejectWithValue(getError('Ошибка удаления файла.'));
        }
    },
);

export const createTaskOfferDoc = createAsyncThunk<
    StatusResponseType,
    CreateTaskOfferDocProps,
    {
        rejectValue: string;
    }
>(
    'clientTask/createTaskOfferDoc',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.createTaskOfferDoc({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            }
        } catch (e) {
            return rejectWithValue(
                getError('Ошибка загрузки файла. Превышен размер или неподходящий формат.'),
            );
        }
    },
);

export const createTaskComment = createAsyncThunk<
    StatusResponseType,
    CreateTaskCommentPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/createTaskComment',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.createTaskComment({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updateTaskComment = createAsyncThunk<
    StatusResponseType,
    UpdateTaskCommentPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/updateTaskComment',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.updateTaskComment({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteTaskComment = createAsyncThunk<
    StatusResponseType,
    UpdateTaskCommentPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/deleteTaskComment',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.deleteTaskComment({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const createTaskReminder = createAsyncThunk<
    StatusResponseType,
    CreateTaskReminderPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/createTaskReminder',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.createTaskReminder({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const deleteTaskReminder = createAsyncThunk<
    StatusResponseType,
    DeleteTaskReminderPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/deleteTaskReminder',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.deleteTaskReminder({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const createTaskDoc = createAsyncThunk<
    StatusResponseType,
    CreateTaskDocPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/createTaskDoc',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.createTaskDocument({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            }
        } catch (e) {
            return rejectWithValue(
                getError('Ошибка загрузки файла. Превышен размер или неподходящий формат.'),
            );
        }
    },
);

export const deleteTaskDoc = createAsyncThunk<
    StatusResponseType,
    DelOrLoadTaskDocumentPropsType,
    {
        rejectValue: string;
    }
>(
    'clientTask/deleteTaskDoc',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.deleteTaskDocument({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            }
        } catch (e) {
            return rejectWithValue(getError('Ошибка удаления файла.'));
        }
    },
);

export const createTaskOfferRequest = createAsyncThunk<
    StatusResponseType,
    CreateTaskOfferRequestProps,
    {
        rejectValue: string;
    }
>(
    'clientTask/createTaskOfferRequest',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.createTaskOfferRequest({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

export const updateTaskOfferRequest = createAsyncThunk<
    StatusResponseType,
    UpdateTaskOfferRequestProps,
    {
        rejectValue: string;
    }
>(
    'clientTask/updateTaskOfferRequest',
    async ({ companyId, taskId, ...props }, { rejectWithValue, dispatch }) => {
        try {
            const res = await clientTaskApi.updateTaskOfferRequest({ taskId, companyId, ...props });
            if (isResponseOK(res)) {
                dispatch(getOneClientTask({ taskId, companyId }));
                return res;
            } else {
                throw res;
            }
        } catch (e) {
            return rejectWithValue(getError(e));
        }
    },
);

const initialState: InitialStateType = {
    clientTasks: [],
    banksForTaskList: [],
    clientTasksCount: 0,
    clientLoanTasksCount: 0,
    clientGuaranteeTasksCount: 0,
    clientTasksAmount: 0,
    clientCommissionAmount: 0,
    clientIncomeAmount: 0,
    currentTask: null,
    isAccessAllowed: false,
    loading: {
        createTask: false,
        updateTask: false,
        updateTaskOffer: false,
        deleteTaskDoc: false,
        comment: false,
        createTaskOffer: false,
        request: false,
        addingReminder: false,
        getTask: false,
        getAllTasks: false,
        uploadTaskOfferFile: false,
        uploadTaskFile: false,
    },
    isRequestFulfilled: {
        createTask: false,
        updateTask: false,
        updateTaskOffer: false,
        deleteTaskDoc: false,
        comment: false,
        reminder: false,
        uploadFile: false,
    },
    error: '',
};

export const clientTaskSlice = createSlice({
    name: 'clientTask',
    initialState,
    reducers: {
        clearClientTaskState: (state) => {
            state = initialState;
            return state;
        },
        resetTasksList: (state) => {
            state.clientTasks = [];
            state.clientTasksAmount = 0;
            state.clientTasksCount = 0;
            state.clientGuaranteeTasksCount = 0;
            state.clientLoanTasksCount = 0;
            return state;
        },
        resetCurrentTask: (state) => {
            state.currentTask = null;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTaskOfferDoc.pending, (state) => {
            state.loading.uploadTaskOfferFile = true;
        });
        builder.addCase(createTaskOfferDoc.fulfilled, (state) => {
            state.loading.uploadTaskOfferFile = false;
        });
        builder.addCase(createTaskDoc.pending, (state) => {
            state.loading.uploadTaskFile = true;
            state.isRequestFulfilled.uploadFile = false;
        });
        builder.addCase(createTaskDoc.fulfilled, (state) => {
            state.loading.uploadTaskFile = false;
            state.isRequestFulfilled.uploadFile = true;
        });
        builder.addCase(createTask.pending, (state) => {
            state.loading.createTask = true;
            state.isRequestFulfilled.createTask = false;
        });
        builder.addCase(createTask.fulfilled, (state, { payload }) => {
            state.currentTask = payload;
            state.isRequestFulfilled.createTask = true;
            state.loading.createTask = false;
        });
        builder.addCase(createTask.rejected, (state, { payload }) => {
            state.loading.createTask = false;
            state.error = payload || '';
        });
        builder.addCase(getAllTasks.pending, (state) => {
            state.loading.getAllTasks = true;
        });
        builder.addCase(getAllTasks.fulfilled, (state, { payload }) => {
            state.loading.getAllTasks = false;
            state.clientTasks = payload.tasks;
            state.clientTasksCount = payload.count;
            state.clientGuaranteeTasksCount = payload.guaranteeTasksCount;
            state.clientLoanTasksCount = payload.loanTasksCount;
            state.clientTasksAmount = payload.amount;
            state.clientCommissionAmount = payload.clientComission;
            state.clientIncomeAmount = payload.clientIncome;
        });
        builder.addCase(getAllTasks.rejected, (state, { payload, meta }) => {
            if (meta.aborted) return;
            state.loading.getAllTasks = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(getOneClientTask.pending, (state) => {
            state.loading.getTask = true;
        });
        builder.addCase(getOneClientTask.fulfilled, (state, { payload }) => {
            state.currentTask = payload;
            state.isAccessAllowed = payload.isAccessAllowed ?? false;
            state.loading.getTask = false;
            state.isRequestFulfilled.uploadFile = false;
        });
        builder.addCase(getOneClientTask.rejected, (state, { payload }) => {
            state.error = payload as string;
            state.loading.getTask = false;
        });
        builder.addCase(updateTask.pending, (state) => {
            state.loading.updateTask = true;
            state.isRequestFulfilled.updateTask = false;
        });
        builder.addCase(updateTask.fulfilled, (state) => {
            state.isRequestFulfilled.updateTask = true;
            state.loading.updateTask = false;
        });
        builder.addCase(updateTask.rejected, (state, { payload }) => {
            state.loading.updateTask = false;
            state.error = payload || '';
        });
        builder.addCase(getBanksListForTask.fulfilled, (state, { payload }) => {
            state.banksForTaskList = payload;
        });
        builder.addCase(getBanksListForTask.rejected, (state, { payload }) => {
            state.error = payload as string;
        });
        builder.addCase(updateTaskOffer.pending, (state) => {
            state.loading.updateTaskOffer = true;
            state.isRequestFulfilled.updateTaskOffer = false;
        });
        builder.addCase(updateTaskOffer.fulfilled, (state) => {
            state.isRequestFulfilled.updateTaskOffer = true;
            state.loading.updateTaskOffer = false;
        });
        builder.addCase(updateTaskOffer.rejected, (state, { payload }) => {
            state.loading.updateTaskOffer = false;
            state.error = payload || '';
        });
        builder.addCase(deleteTaskDoc.pending, (state) => {
            state.loading.deleteTaskDoc = true;
            state.isRequestFulfilled.deleteTaskDoc = false;
        });
        builder.addCase(deleteTaskDoc.fulfilled, (state) => {
            state.isRequestFulfilled.deleteTaskDoc = true;
            state.loading.deleteTaskDoc = false;
        });
        builder.addCase(deleteTaskDoc.rejected, (state, { payload }) => {
            state.loading.deleteTaskDoc = false;
            state.error = payload || '';
        });
        builder.addCase(createTaskComment.pending, (state) => {
            state.loading.comment = true;
            state.isRequestFulfilled.comment = false;
        });
        builder.addCase(createTaskComment.fulfilled, (state) => {
            state.loading.comment = false;
            state.isRequestFulfilled.comment = true;
        });
        builder.addCase(createTaskComment.rejected, (state, { payload }) => {
            state.loading.comment = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(updateTaskComment.pending, (state) => {
            state.loading.comment = true;
            state.isRequestFulfilled.comment = false;
        });
        builder.addCase(updateTaskComment.fulfilled, (state) => {
            state.loading.comment = false;
            state.isRequestFulfilled.comment = true;
        });
        builder.addCase(updateTaskComment.rejected, (state, { payload }) => {
            state.loading.comment = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
            state.error = payload as string;
        });
        builder.addCase(createTaskOffer.pending, (state) => {
            state.loading.createTaskOffer = true;
        });
        builder.addCase(createTaskOffer.fulfilled, (state) => {
            state.loading.createTaskOffer = false;
        });
        builder.addCase(createTaskOffer.rejected, (state) => {
            state.loading.createTaskOffer = false;
        });
        builder.addCase(createTaskOfferRequest.pending, (state) => {
            state.loading.request = true;
        });
        builder.addCase(createTaskOfferRequest.fulfilled, (state) => {
            state.loading.request = false;
        });
        builder.addCase(createTaskOfferRequest.rejected, (state, { payload }) => {
            state.loading.request = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(updateTaskOfferRequest.pending, (state) => {
            state.loading.request = true;
        });
        builder.addCase(updateTaskOfferRequest.fulfilled, (state) => {
            state.loading.request = false;
        });
        builder.addCase(updateTaskOfferRequest.rejected, (state, { payload }) => {
            state.loading.request = false;
            if (typeof payload !== 'string') return;
            toast.error(payload);
        });
        builder.addCase(createTaskReminder.pending, (state) => {
            state.loading.addingReminder = true;
            state.isRequestFulfilled.reminder = false;
        });
        builder.addCase(createTaskReminder.fulfilled, (state) => {
            state.loading.addingReminder = false;
            state.isRequestFulfilled.reminder = true;
        });
        builder.addCase(createTaskReminder.rejected, (state, { payload }) => {
            state.loading.addingReminder = false;

            if (typeof payload !== 'string') return;

            toast.error(payload);
        });
    },
});

export const { clearClientTaskState, resetTasksList, resetCurrentTask } = clientTaskSlice.actions;

export const selectClientTask = (state: RootState) => state.clientTask;
