import { OfferDocTypes, TaskСloseReasonFilter } from '../../utils/data';
import {
    ClientTaskInterface,
    PartnerInterface,
    TaskImportanceTypes,
    TaskProductTypes,
    TaskStatusTypes,
} from './../../utils/types';

/* eslint-disable */
export enum clientTaskApiPath {
    CRUD_TASK = '/task',
    GET_ALL_TASKS = 'company/tasks',
    GET_BANKS = '/banks',
    CRUD_OFFER = '/offer',
    CRUD_COMMENT = '/comment',
    CRUD_REMINDER = '/reminder',
    CRUD_DOCS = '/documents',
    DOWNLOAD_DOCS = '/documents/download',
    REQUEST = '/request',
}
/* eslint-enable */

export type CreateTaskPropsType = {
    companyId: number;
    procurementProcedureNumber: string;
    procurementProcedureLink: string;
    federalLaw: string;
    bankGuaranteeType: string;
    bankGuaranteeAmount: number;
    bankGuaranteeTermFrom?: string;
    bankGuaranteeTermTo?: string;
    bankGuaranteeTermDays?: number;
    contractAdvanceAvailability: string;
    additComment?: string;
    executorId?: number;
};

export type CreateTaskResponseType =
    | {
          data: ClientTaskInterface;
          message: string;
      }
    | undefined;

export type GetTasksListActivityType = 'active' | 'inactive';

export type GetAllClientTasksPropsType = {
    managerId?: number;
    executorId?: number;
    companyId?: number;
    closeReason?: TaskСloseReasonFilter;
    closeDateFrom?: string;
    closeDateTo?: string;
    activityType: GetTasksListActivityType;
    abortSignal?: AbortSignal;
};

export type GetAllClientTasksResponseType =
    | {
          data: {
              tasksList: ClientTaskInterface[];
              total: {
                  records: number;
                  guaranteeRecords: number;
                  loanRecords: number;
                  bkw: number;
                  clientKw: number;
                  clientIncome: number;
              };
              isAccessAllowed?: boolean;
          };
          message: string;
      }
    | undefined;

export type GetOneClientTaskPropsType = {
    companyId: number;
    taskId: number;
};

export type GetOneClientTaskResponseType =
    | {
          data: ClientTaskInterface;
          message: string;
      }
    | undefined;

export type UpdateTaskPropsType = {
    companyId: number;
    taskId: number;
    procurementProcedureNumber?: string;
    procurementProcedureLink?: string;
    federalLaw?: string;
    bankGuaranteeType?: string;
    bankGuaranteeAmount?: number;
    bankGuaranteeTermFrom?: string;
    bankGuaranteeTermTo?: string;
    bankGuaranteeTermDays?: number;
    additComment?: string;
    status?: TaskStatusTypes;
    importance?: TaskImportanceTypes;
    contractConclusionDate?: string;
    procurementProcedureName?: string;
    initialContractPrice?: number;
    closeReason?: string | null;
    closeComment?: string | null;
    closeDate?: string | null;
    userWhoClosedTask?: number | null;
    importantInfo?: string;
    executorId?: number;
    coExecutorId?: number;
    postscript?: string;
    type: TaskProductTypes;
    loanType?: string;
    loanAmount?: number;
    loanTermMonths?: number;
};

export type GetBanksForTaskResponseType =
    | {
          data: PartnerInterface[];
          message: string;
      }
    | undefined;

export type CreateTaskOfferPropsType = {
    companyId: number;
    taskId: number;
    productId: number;
};

export type UpdateTaskOfferPropsType = {
    companyId: number;
    taskId: number;
    offerId: number;
    bankCommission?: number;
    baseCommission?: number;
    clientCommission?: number;
    bankCommissionInRub?: number;
    baseCommissionInRub?: number;
    clientCommissionInRub?: number;
    agentSeparation?: number;
    comment?: string;
    overstatement?: number;
    status?: string;
    rateFrom?: number;
    amount?: number;
    term?: number;
};

export type CreateTaskOfferDocProps = {
    companyId: number;
    taskId: number;
    offerId: number;
    name: string;
    file: string;
    section: OfferDocTypes;
};

export type DeleteTaskOfferDocProps = {
    companyId: number;
    taskId: number;
    offerId: number;
    taskOfferDocumentId: number;
};

export type CreateTaskCommentPropsType = {
    companyId: number;
    taskId: number;
    text: string;
};

export type UpdateTaskCommentPropsType = {
    companyId: number;
    taskId: number;
    commentId: number;
    text?: string;
};

export type DeleteTaskCommentPropsType = {
    companyId: number;
    taskId: number;
    commentId: number;
};

export type CreateTaskReminderPropsType = {
    companyId: number;
    taskId: number;
    text: string;
    time: string;
};

export type CreateTaskDocPropsType = {
    companyId: number;
    taskId: number;
    name: string;
    file: string;
};

export type DelOrLoadTaskDocumentPropsType = {
    companyId: number;
    taskId: number;
    documentId: number;
};

export type DeleteTaskReminderPropsType = {
    companyId: number;
    taskId: number;
    reminderId: number;
};

export type CreateTaskOfferRequestProps = {
    companyId: number;
    taskId: number;
    offerId: number;
    text: string;
};

export type UpdateTaskOfferRequestProps = {
    companyId: number;
    taskId: number;
    offerId: number;
    requestId: number;
    text?: string;
    isActive?: boolean;
};
