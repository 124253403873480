import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import { getOneClientTask, selectClientTask } from '../../store/slices/clientTaskSlice';
import { getCompany, selectClientCard } from '../../store/slices/clientCardSlice';
import { userSelector } from '../../store/slices/userSlice';
import ClientInteraction from '../ClientInteraction/ClientInteraction';
import DefaultLoader from '../../components/Common/BtnLoader/DefaultLoader';
import StyledTaskPage from './ClientTaskPage.style';

const ClientTaskPage = () => {
    const dispatch = useAppDispatch();
    const { companyId, taskId } = useParams();
    const { loading: taskLoading, currentTask, isAccessAllowed } = useSelector(selectClientTask);
    const { loading: companyLoading, company } = useSelector(selectClientCard);
    const { user } = useSelector(userSelector);
    const showLoader = companyLoading.company || taskLoading.getTask;

    const isUserExecutor =
        currentTask?.executor?.userId === user?.userId ||
        currentTask?.coExecutor?.userId === user?.userId;
    const normalizedCompanyId = Number(companyId);
    const normalizedTaskId = Number(taskId);
    const hasRight =
        user?.isAdmin ||
        company?.manager?.userId === user?.userId ||
        isUserExecutor ||
        isAccessAllowed;

    useEffect(() => {
        if (!normalizedTaskId || !normalizedCompanyId) return;

        dispatch(getOneClientTask({ taskId: normalizedTaskId, companyId: normalizedCompanyId }));
        dispatch(getCompany({ companyId: normalizedCompanyId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [normalizedCompanyId, normalizedTaskId]);

    if (!hasRight || !taskId) {
        return (
            <StyledTaskPage>{showLoader ? <DefaultLoader /> : <h1>Нет доступа</h1>}</StyledTaskPage>
        );
    }

    return <ClientInteraction taskId={normalizedTaskId} />;
};

export default ClientTaskPage;
